import { VaporProvider } from '@vapor/ui';
import { SWRConfig } from 'swr';
import Head from 'next/head';
import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import { getCookie, removeCookie } from 'tiny-cookie';
import '../styles/reset.css';
import '../styles/variables.css';
import '../styles/global.css';
import { MustBeLoggedIn, notLoggedInHandler } from '../state/useAres';
import { config } from '../../config';

if (typeof window != 'undefined') {
  smoothscroll.polyfill();
}

let version: string;

let checkVersion = () => {
  if (!version || document.hidden) return;

  fetch('/version.json')
    .then(res => res.json())
    .then(res => {
      if (version != res.version) {
        window.location.reload();
      }
    })
    .catch(() => {});
};

setInterval(checkVersion, 1000 * 60);

if (typeof window != 'undefined') {
  document.addEventListener('visibilitychange', checkVersion);

  fetch('/version.json')
    .then(res => res.json())
    .then(res => {
      version = res.version;
    })
    .catch(() => console.log('Unable to fetch version'));
}

function MyApp({ Component, pageProps }) {
  return (
    <SWRConfig
      value={{
        shouldRetryOnError: false
      }}
    >
      <VaporProvider ssr>
        <Head>
          <link rel="stylesheet" href="https://fonts.onhighloop.com/inter/index.css" />
          <script src="https://holidays.highloop.co/index.js" />
          <title>Projects | Highloop</title>
        </Head>

        <MustBeLoggedIn />

        <Component {...pageProps} />

        <script src="https://components.onhighloop.com/more/index.js" />
      </VaporProvider>
    </SWRConfig>
  );
}

export default MyApp;
