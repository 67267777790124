import { createAres } from '@highloop-pkg/use-ares';
import { getCookie } from 'tiny-cookie';
import * as Sentry from '@sentry/nextjs';
import { config } from '../../config';

let ares = createAres({
  aresHost: config.projectService,
  authHost: config.authFrontend,
  getToken: () => {
    let token = getCookie('token');
    if (!token) ares.notLoggedInHandler();
    return token;
  },
  product: {
    name: 'Start',
    id: 'start',
    host: 'https://start.highloop.co'
  },
  onError: error => {
    Sentry.captureException(error);
  }
});

export let useBoot = ares.useBoot;
export let useUser = ares.useUser;
export let useProject = ares.useProject;
export let useProjects = ares.useProjects;
export let useProjectMembers = ares.useProjectMembers;
export let useProjectId = ares.useProjectId;
export let useNotLoggedIn = ares.useNotLoggedIn;
export let MustBeLoggedIn = ares.MustBeLoggedIn;
export let getToken = ares.getToken;
export let getAuthHeaders = ares.getAuthHeaders;
export let notLoggedInHandler = ares.notLoggedInHandler;
