export let config = {
  currentUrl: 'https://start.highloop.co',

  weekriseApi: 'https://weekrise-api.onhighloop.com',
  authService: 'https://auth.onhighloop.com/api',
  authFrontend: 'https://auth.onhighloop.com',
  projectService: 'https://ares.onhighloop.com',
  projectFrontend: 'https://projects.highloop.co',
  accountFrontend: 'https://account.highloop.co'
};

if (process.env.NODE_ENV === 'development') {
  config = {
    currentUrl: 'http://localhost:3106',

    weekriseApi: 'http://localhost:4200',
    authService: 'http://localhost:3100/api',
    authFrontend: 'http://localhost:3100',
    projectService: 'http://localhost:4102',
    projectFrontend: 'http://localhost:3102',
    accountFrontend: 'http://localhost:3101'
  };
}
